import { useDeepCompareEffect } from '@swe/shared/hooks/use-deep-compare';
import { useLayout } from '@swe/shared/providers/layout';
import { useBreakpoint } from '@swe/shared/tools/media';
import { Alert } from '@swe/shared/ui-kit/components/alert';
import Button, { ButtonColor } from '@swe/shared/ui-kit/components/button';
import Grid from '@swe/shared/ui-kit/components/grid';

import { InfoIcon } from '@swe/shared/ui-kit/components/icon';
import { Portal } from '@swe/shared/ui-kit/components/portal';
import Stack from '@swe/shared/ui-kit/components/stack';

import { Text } from '@swe/shared/ui-kit/components/text';
import { Tooltip } from '@swe/shared/ui-kit/components/tooltip';
import { PatternThemeProvider, useTheme } from '@swe/shared/ui-kit/theme/provider';
import { formatPrice } from '@swe/shared/utils/price';

import { useCallback, useEffect, useMemo, useRef } from 'react';

import styles from './styles.module.scss';

import { FixedFooterBox } from 'common/components/fixed-footer-box';
import { ABTest, useABTest } from 'common/containers/ab-test';
import { AdditionalProductsCarousel } from 'common/containers/additional-products-carousel';
import { PromoRecommendation } from 'common/containers/promo-recommendation';
import { getPrepAnalyticsCartItems } from 'common/entities/product-analitycs';
import { useAnalytics } from 'common/providers/analytics';
import { AEventType } from 'common/providers/analytics/constants';
import { ProductAvailability, useCartUtils } from 'common/providers/cart';
import { useMichigan } from 'common/use-cases/use-michigan';
import { useSaleType } from 'common/use-cases/use-sale-type';
import { mergePromosList } from 'common/utils/promo-recommendations';
import { EmptyCart } from 'domains/cart/components/empty-cart';
import { List } from 'domains/cart/components/list';
import { Summary } from 'domains/cart/components/summary';
import { UnableToRestore } from 'domains/cart/components/unable-to-restore';
import { UltimatePromo } from 'domains/cart/containers/ultimate-promo';
import { useCart } from 'domains/cart/use-cases/use-cart';
import { useUltimatePromo } from 'domains/cart/use-cases/use-ultimate-promo';
import { useCatalogQuery } from 'domains/catalog/use-cases/use-catalog-query';
import { ABTestEnumerable } from 'entities/analytics/ab-test';

const calculateTotalSavings = (beforePromo: number, afterPromo: number): string => {
  const savings = beforePromo - afterPromo;
  return savings.toFixed(2);
};

type CheckoutButtonProps = {
  pending?: boolean;
  disabled?: boolean;
  total?: number;
  proceedToCheckout: () => Promise<void>;
  info?: string;
  totalBeforePromo: number;
};
const CheckoutButton = ({
  pending,
  total,
  proceedToCheckout,
  disabled,
  info,
  totalBeforePromo,
}: CheckoutButtonProps) => {
  const { mobile } = useBreakpoint();
  const theme = useTheme();

  const totalSavingElement = useMemo(() => {
    const formattedPrice = total ? formatPrice(total) : '';
    if (total && totalBeforePromo - total > 0) {
      return (
        <>
          {`Checkout ~${formattedPrice}`}
          <Text
            variant="body"
            align="center"
          >
            Total savings: ${calculateTotalSavings(totalBeforePromo, total)}
          </Text>
        </>
      );
    }
    return `Checkout ${formattedPrice}`;
  }, [totalBeforePromo, total]);

  return (
    <Stack direction="row">
      <PatternThemeProvider
        name="order-summary"
        className={styles.checkoutButton}
      >
        <Button
          block
          color={theme.cartSummary.button.style.purchase as ButtonColor}
          pending={pending}
          onClick={proceedToCheckout}
          disabled={disabled}
          name="checkout"
        >
          {totalSavingElement}
        </Button>
      </PatternThemeProvider>
      {mobile && info && (
        <Tooltip
          content={info}
          trigger="click"
        >
          <Button
            type="button"
            color="light"
            icon={InfoIcon}
            ariaLabel={info}
          />
        </Tooltip>
      )}
    </Stack>
  );
};

const Cart = () => {
  const { desktop, tablet } = useBreakpoint();
  const {
    cart,
    isLoading,
    isCartRestorationFailed,
    proceedToCheckout: _proceedToCheckout,
    isOutOfLimit,
    availableItems,
    unavailableItems,
    price,
    removeUnavailableItems,
    alertBock,
    variantIds,
    isEmpty,
    isLoadingUpdate,
    isValidating,
    pendingCheckout,
    RestorationModal,
    removeFromCart,
    caregiverPatientsSelectOptions,
    isCaregiver,
    user,
    isLoadingAdd,
    isLoadingRemove,
  } = useCart();
  const { update } = useCartUtils();

  const { props, wrapProceedToCheckout } = useUltimatePromo({
    cart,
    cartPending: isLoading || isValidating || isLoadingAdd || isLoadingRemove,
    variantIds,
    price,
  });

  const proceedToCheckout = useCallback(
    () => wrapProceedToCheckout(_proceedToCheckout),
    [_proceedToCheckout, wrapProceedToCheckout],
  );

  const { saleType } = useSaleType();

  const caregiverHandler = useCallback(
    (patientId: number) => {
      if (user) {
        void update({
          ...cart,
          patientId: !patientId ? null : patientId,
          flushPatientId: !patientId,
        });
      }
    },
    [cart, update, user],
  );

  const checkoutInfo = isOutOfLimit
    ? "Can't proceed to checkout while limits are exceeded"
    : 'Purchase total, including taxes and discounts, will be shown at checkout';
  const checkoutButton = (
    <CheckoutButton
      pending={isLoading || isValidating || isLoadingUpdate || pendingCheckout}
      disabled={isOutOfLimit || unavailableItems.length !== 0}
      total={price.total}
      totalBeforePromo={price.totalBeforePromo}
      proceedToCheckout={proceedToCheckout}
      info={checkoutInfo}
    />
  );

  const { pushEvent } = useAnalytics();

  const alreadySent = useRef(false);

  useEffect(() => {
    if (!cart || alreadySent.current) {
      return;
    }
    pushEvent(AEventType.VIEW_CART, getPrepAnalyticsCartItems(cart, user));
    alreadySent.current = true;
  }, [cart, pushEvent, user]);

  const { toCatalog } = useCatalogQuery();
  const goToCatalog = useCallback(() => {
    void toCatalog({ filters: null, searchTerm: null, subPath: null });
  }, [toCatalog]);

  const { headerRect } = useLayout();

  const { checkoutDisclaimer } = useMichigan();
  const { enabled, group } = useABTest(ABTestEnumerable.SpecialPromoInCart);

  useDeepCompareEffect(() => {
    if (!isLoading && enabled && cart?.order?.cartPromoRecommendations?.length) {
      const payload = cart?.order?.cartPromoRecommendations.reduce<{
        promo_ids: number[];
        segments: number[];
      }>(
        (acc, { id, matchedUserSegmentIds }) => {
          return {
            promo_ids: [...acc.promo_ids, id],
            segments: [...acc.segments, ...matchedUserSegmentIds],
          };
        },
        { promo_ids: [], segments: [] },
      );

      pushEvent(AEventType.PROMO_LOADED_FROM_BACKEND, payload);
      if (group === 'group1') {
        pushEvent(AEventType.PROMO_DISPLAYED_IN_CART, payload);
      }
    }
  }, [cart?.order?.cartPromoRecommendations, enabled, group, isLoading, pushEvent]);

  const promoRecommendationCardElement = useMemo(() => {
    if (!(cart?.order?.cartPromoRecommendations && cart.order.cartAppliedPromos)) return;

    const recommendations = mergePromosList(cart.order.cartPromoRecommendations, cart.order.cartAppliedPromos);
    if (recommendations.length === 0) return null;

    return (
      <ABTest testName={ABTestEnumerable.SpecialPromoInCart}>
        {({ group }) => {
          switch (group) {
            case 'group1':
              return <PromoRecommendation recommendations={recommendations} />;
            case 'control':
              return null;
          }
        }}
      </ABTest>
    );
  }, [cart?.order?.cartPromoRecommendations, cart?.order?.cartAppliedPromos]);

  const MainContent = (
    <Grid.Row spacing={{ desktop: 'xxl', tablet: 'sm', mobile: 'sm' }}>
      <Grid.Cell
        order={desktop ? 1 : 2}
        cols={{ mobile: 12, tablet: 12, desktop: 'auto' }}
      >
        <Stack spacing="sm">
          {!desktop && !cart?.order?.limits?.length && !isCaregiver && promoRecommendationCardElement}
          {(alertBock.title || alertBock.content) && <Alert title={alertBock.title}>{alertBock.content}</Alert>}
          {checkoutDisclaimer}
          <List
            availableItems={availableItems.map(({ availability, ...rest }) => ({
              ...rest,
              unavailable: '',
              onRemoveAll: () => removeFromCart(rest),
            }))}
            unavailableItems={unavailableItems.map(({ availability, ...rest }) => ({
              ...rest,
              unavailable:
                availability === ProductAvailability.OutOfStock
                  ? 'Out of Stock'
                  : availability === ProductAvailability.OnlyMedical
                    ? 'Medical purchase only'
                    : 'Recreation purchase only',
              onRemoveAll: () => removeFromCart(rest),
            }))}
            isLoading={isLoading}
            saleType={saleType}
            onRemoveAll={removeUnavailableItems}
          />
        </Stack>
      </Grid.Cell>
      {((!desktop && cart?.order?.limits?.length) || desktop || isCaregiver) && (
        <Grid.Cell
          order={desktop ? 2 : 1}
          cols={{ mobile: 12, tablet: 12, desktop: 4 }}
        >
          <div
            className={styles.summaryCard}
            style={{ position: 'sticky', top: headerRect.height + 24 }}
          >
            {promoRecommendationCardElement}
            <Summary
              saleType={saleType}
              limits={cart?.order?.limits}
              limitsAdditional={cart?.biotrackFloridaLimits}
              isLoading={!cart && (isLoading || isValidating)}
              checkoutButton={desktop && checkoutButton}
              caregiverPatientsSelectOptions={caregiverPatientsSelectOptions}
              caregiverHandler={caregiverHandler}
              patient={cart?.order?.patient}
            />
          </div>
        </Grid.Cell>
      )}
    </Grid.Row>
  );

  return (
    <>
      <Stack
        spacing={{
          desktop: 'xxxxl',
          tablet: 'md',
          mobile: 'lg',
        }}
      >
        {isLoading ? (
          MainContent
        ) : (
          <>
            {isCartRestorationFailed && <UnableToRestore />}
            {!isCartRestorationFailed &&
              (isEmpty ? (
                <EmptyCart goToCatalog={goToCatalog} />
              ) : (
                <>
                  {MainContent}
                  {!desktop && (
                    <Portal name="fixedFooter">
                      <FixedFooterBox>
                        <Stack>
                          {tablet && (
                            <Text>Final amount, including taxes and discounts, will be shown at checkout</Text>
                          )}
                          {checkoutButton}
                        </Stack>
                      </FixedFooterBox>
                    </Portal>
                  )}
                </>
              ))}
            <AdditionalProductsCarousel
              title="You may also be interested in"
              variantIds={variantIds}
              keepPrevious
              origin="cart"
            />
          </>
        )}
      </Stack>
      <UltimatePromo {...props} />
      {RestorationModal}
    </>
  );
};

export { Cart };
export default Cart;
