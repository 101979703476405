import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import { useBreakpoint } from '@swe/shared/tools/media';
import Alert from '@swe/shared/ui-kit/components/alert';
import AnimatedHeight from '@swe/shared/ui-kit/components/animated-height';
import { Box } from '@swe/shared/ui-kit/components/box';
import { RadioGroup } from '@swe/shared/ui-kit/components/form/radio-group';
import { ChevronDownIcon, ChevronUpIcon, InfoIcon, QuestionIcon } from '@swe/shared/ui-kit/components/icon';
import { SectionHeading } from '@swe/shared/ui-kit/components/section-heading';
import { Stack } from '@swe/shared/ui-kit/components/stack';

import { Text } from '@swe/shared/ui-kit/components/text';

import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import styles from './styles.module.scss';

import { isOutOfLimits } from 'common/providers/cart';
import { Limit } from 'domains/cart/components/summary/components/limit';

import { SALE_TYPE_TO_LABEL } from 'domains/cart/constants';

import { PurchaseLimit } from 'entities/common/orders';
import { ProductSaleType } from 'entities/product/product';

type CardSummaryProps = {
  CheckoutButton: ReactNode;
  limits?: PurchaseLimit[];
  limitsAdditional?: PurchaseLimit[];
  patientName?: string;
  saleType?: ProductSaleType;
};

const filterExceed = (l: { limitAmount: number; maxAmount: number }) => l.limitAmount > l.maxAmount;

const NAME_MAP: { [key: number]: string } = {
  3: 'Smoking',
  2: 'Medical',
  1: 'Low THC',
};

const CardSummary = ({
  CheckoutButton,
  limits = [],
  limitsAdditional = [],
  patientName,
  saleType,
}: CardSummaryProps) => {
  const [isOpen, open, , toggle] = useToggleable(false);
  const { desktop } = useBreakpoint();

  let _topListLimits = useMemo(() => [...limits], [limits]);

  let _hiddenListLimits = useMemo(() => {
    const itemsKeys: Record<string, number> = {};
    return limitsAdditional?.filter(({ orderTypeId, nameAbbr, limitAmount, maxAmount, uomAbbr }) => {
      const key = `${orderTypeId}${nameAbbr}${limitAmount}${maxAmount}${uomAbbr}`;

      if (itemsKeys[key]) {
        return false;
      }

      itemsKeys[key] = 1;
      return true;
    });
  }, [limitsAdditional]);

  const isOutOfLimitAdditional = isOutOfLimits(_hiddenListLimits);

  if (isOutOfLimitAdditional) {
    _topListLimits = _topListLimits.concat(
      _hiddenListLimits?.filter(filterExceed).map(({ nameAbbr, orderTypeId, ...rest }) => ({
        ...rest,
        nameAbbr: `${nameAbbr}${orderTypeId ? ` (${NAME_MAP[orderTypeId]})` : ''}`,
      })),
    );
  }

  if (!desktop) {
    if (isOutOfLimitAdditional) {
      if (!isOpen) {
        _topListLimits = _topListLimits.filter(filterExceed);
      }
    } else {
      _hiddenListLimits = _topListLimits.concat(_hiddenListLimits || []);
    }
  }

  const _sortedLimits = [..._topListLimits].sort((a, b) => a.limitAmount - b.maxAmount);

  const isOutOfLimitCommon = isOutOfLimits(_sortedLimits);

  useEffect(() => {
    if (isOutOfLimitCommon || isOutOfLimitAdditional) {
      open();
    }
  }, [isOutOfLimitAdditional, isOutOfLimitCommon, open]);

  const hasLimits = _sortedLimits.length > 0 || _hiddenListLimits.length > 0;

  const header = hasLimits ? (
    <SectionHeading
      size="md"
      subHeading={patientName ? `For ${patientName}` : undefined}
      colorText={isOutOfLimitCommon ? 'danger' : undefined}
      onClick={toggle}
      button={
        _hiddenListLimits.length
          ? {
              icon: isOpen ? ChevronUpIcon : ChevronDownIcon,
              color: 'light',
            }
          : undefined
      }
      badge={
        isOutOfLimitCommon
          ? {
              icon: QuestionIcon,
              color: 'danger',
              tooltip: (
                <>
                  Legal limits are exceeded.
                  <br /> Please remove some products containing excessive category from the cart
                </>
              ),
            }
          : undefined
      }
    >
      {isOutOfLimitCommon ? 'Exceeded limits' : saleType ? `${SALE_TYPE_TO_LABEL[saleType]} Limits` : 'Purchase Limits'}
    </SectionHeading>
  ) : null;

  const desktopAlertElement = desktop ? (
    <Box mt="md">
      <Alert
        icon={InfoIcon}
        color="neutral"
      >
        Purchase total, including taxes and discounts, will be shown at checkout
      </Alert>
    </Box>
  ) : null;

  const desktopDisclaimerText =
    isOpen && limitsAdditional?.length ? (
      <Box mt="md">
        <Text
          size="lg"
          className={styles.disclaimerText}
        >
          Certain routes can be changed in-store
        </Text>
      </Box>
    ) : null;

  const _limitsAdditionalGrouped = useMemo(
    () =>
      _hiddenListLimits?.reduce<Record<number, { items: PurchaseLimit[]; orderTypeId: number }>>(
        (acc, { orderTypeId, ...item }) => {
          if (!orderTypeId) {
            return acc;
          }

          if (acc[orderTypeId]) {
            acc[orderTypeId].items.push(item);
          } else {
            acc[orderTypeId] = {
              items: [item],
              orderTypeId,
            };
          }
          return acc;
        },
        {},
      ),
    [_hiddenListLimits],
  );

  const tabs = useMemo(
    () =>
      Object.values(_limitsAdditionalGrouped)
        .map(({ orderTypeId }) => ({
          label: NAME_MAP[orderTypeId],
          value: orderTypeId,
          orderTypeId,
        }))
        .sort((a, b) => b.orderTypeId - a.orderTypeId),
    [_limitsAdditionalGrouped],
  );

  const [selectedType, setSelectedType] = useState<number>(-1);

  useEffect(() => {
    if (selectedType === -1 && tabs[0]) {
      setSelectedType(tabs[0].value);
    }
    if (selectedType !== -1 && !_limitsAdditionalGrouped[selectedType]) {
      if (tabs[0]) {
        setSelectedType(tabs[0].value);
      } else {
        setSelectedType(-1);
      }
    }
  }, [_limitsAdditionalGrouped, selectedType, tabs]);

  const _limitsAdditionalGroupedSelected = useMemo(
    () => _limitsAdditionalGrouped[selectedType]?.items || [],
    [_limitsAdditionalGrouped, selectedType],
  );

  const renderLimit = ({ nameAbbr, limitAmount, maxAmount, uomAbbr }: PurchaseLimit, idx: number) => {
    return (
      <Limit
        key={idx}
        name={nameAbbr}
        current={limitAmount}
        max={maxAmount}
        abbreviation={uomAbbr}
      />
    );
  };

  const itemsCommon = hasLimits ? (
    <Box mt={_sortedLimits.length || isOpen ? 'md' : 'none'}>
      {_sortedLimits.length > 0 && desktop ? <Stack spacing="sm">{_sortedLimits.map(renderLimit)}</Stack> : null}
      {_hiddenListLimits.length ? (
        <AnimatedHeight expanded={isOpen}>
          <Stack
            spacing="sm"
            mt={_sortedLimits.length ? 'md' : 'none'}
          >
            {_sortedLimits.length > 0 && !desktop ? <Stack spacing="sm">{_sortedLimits.map(renderLimit)}</Stack> : null}
            {_limitsAdditionalGroupedSelected.length > 0 && (
              <>
                <SectionHeading size="sm">Form Limits</SectionHeading>
                <RadioGroup
                  variant="section"
                  name="type"
                  value={selectedType}
                  options={tabs}
                  onChange={setSelectedType}
                />
                {_limitsAdditionalGroupedSelected.map(renderLimit)}
              </>
            )}
          </Stack>
        </AnimatedHeight>
      ) : null}
    </Box>
  ) : null;

  return (
    <div>
      {CheckoutButton ? <Box mt="md">{CheckoutButton}</Box> : null}
      {desktopAlertElement}
      {header}
      {itemsCommon}
      {desktopDisclaimerText}
    </div>
  );
};

export { CardSummary };
export type { CardSummaryProps };
